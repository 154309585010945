<template>
	<div>
		<div class="toolBar mBottom10">
			<el-button type="primary" size='small' plain @click="searchBus">查询</el-button>
			<el-button type="primary" plain size='small' @click="addBus">新增</el-button>
			<el-button plain size='small' class='fr' @click="refresh">刷新</el-button>
		</div>

		<el-card class="box-card" style="margin-top: 10px">
			<el-table :data="businessConData"  size='small' stripe :height='tableHeight'>
				<el-table-column type="index" align="center" label='序号'></el-table-column>
				<el-table-column prop="moduleName" label="业务名称" align="left"></el-table-column>
				<el-table-column prop="role" label="系统角色" align="left"></el-table-column>
				<el-table-column prop="useOrg" label="使用机构" align="left"></el-table-column>
				<el-table-column  label="操作" align="center" width="200">
					<template slot-scope="scope">
						<el-button size="mini" type="text" @click="editBusiness(scope.row)">编辑</el-button>
						<el-button size="mini" type="text" @click="delBusiness(scope.row)">删除</el-button>
						<el-button size="mini" type="text" @click="roleConfig(scope.row)">关联角色</el-button>
					</template>
				</el-table-column>
			</el-table>
		</el-card>

		<div class="toolBar clearfix" style="margin-top: 10px">
			<pagi-nation :page='page' @sizeChange='businessConSizeChange' @currentChange='businessConCurrentChange'></pagi-nation>
		</div>

		<!-- 查询 -->
		<el-dialog title="查询" v-dialogDrag :visible.sync="isShowSelDlg" width="40%">
			<el-form :model="selBusinessData" :rules="rules"  label-width="100px" size='small'>
				<el-row>
					<el-col :sm="24">
						<el-form-item label="业务名称">
							<el-input clearable v-model="selBusinessData.moduleName"></el-input>
						</el-form-item>
					</el-col>
					<el-col :sm="24">
						<el-form-item label="业务关键字">
							<el-input clearable v-model="selBusinessData.keyWord"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
			<span slot="footer" class="dialog-footer">
					<el-button @click="isShowSelDlg = false" size='small'>取 消</el-button>
					<el-button type="primary" @click="searchBusiness" size='small'>确 定</el-button>
				  </span>
		</el-dialog>

		<!-- 增加 -->
		<el-dialog title="新增业务" v-dialogDrag :visible.sync="isShowAddDlg" width="40%">
			<el-form  ref="businessForm" :model="addBusinessData" :rules="rules" size='small' label-width="100px">
				<el-row>
					<el-col :sm="24">
						<el-form-item label="业务名称" prop="moduleName">
							<el-input clearable v-model="addBusinessData.moduleName" placeholder="长度不超过16个汉字"></el-input>
						</el-form-item>
					</el-col>
					<el-col :sm="24">
						<el-form-item label="业务关键字" prop="keyWord">
							<el-input clearable v-model="addBusinessData.keyWord"  placeholder="关键字为字母，且不可重复，长度不超过32"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
			<span slot="footer" class="dialog-footer">
					<el-button @click="isShowAddDlg = false" size='small'>取消</el-button>
					<el-button type="primary" @click="addBusiness" size='small'>确定</el-button>
				  </span>
		</el-dialog>

		<!-- 修改 -->
		<el-dialog title="编辑业务" v-dialogDrag :visible.sync="isShowEditDlg" width="40%">
			<el-form  ref="businessForm2" :model="editBusinessData" size='small' :rules="rules"  label-width="100px">
				<el-row>
					<el-col :sm="24">
						<el-form-item label="业务名称" prop="moduleName">
							<el-input clearable v-model="editBusinessData.moduleName"></el-input>
						</el-form-item>
					</el-col>
					<el-col :sm="24">
						<el-form-item label="业务关键字" prop="keyWord">
							<el-input clearable v-model="editBusinessData.keyWord"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
			<span slot="footer" class="dialog-footer">
						<el-button @click="isShowEditDlg = false" size='small'>取消</el-button>
						<el-button type="primary" @click="updateBusiness()" size='small'>确定</el-button>
					  </span>
		</el-dialog>


		<!-- 关联角色 -->
		<el-dialog title="关联角色"
				   v-dialogDrag
				   :visible.sync="isShowRoleDlg"
				   width="30%">

			<el-table ref="multipleTable" :data="roleList" tooltip-effect="dark" style="width: 100%"
					@selection-change="handleSelectionChange">
				<el-table-column type="selection" width="55"></el-table-column>
				<el-table-column type="index" align="center" label='序号' width="120"></el-table-column>
				<el-table-column prop="name" label="角色名称" width="150"></el-table-column>
			</el-table>
			<span slot="footer" class="dialog-footer">
					<el-button @click="isShowRoleDlg = false">取 消</el-button>
					<el-button type="primary" @click="addModuleRole">确 定</el-button>
				  </span>
		</el-dialog>
	</div>
</template>

<script>
    class Business{
        constructor(){
            this.moduleName = null;
            this.keyWord = null;
        }
        reset(){
            this.constructor();
        }
    };
	export default {
		data(){
			return{
				businessConData:[],
				page:new this.Page(),
                rules:{
                    moduleName:[{ required: true, message: '业务名称是必须的', trigger: 'blur' }],
                    keyWord:[{ required: true, message: '关键字是必须的，且不可重复', trigger: 'blur' }],
                },

                isShowAddDlg: false,
                isShowEditDlg:false,
                isShowSelDlg: false,

				tableHeight: document.documentElement.clientHeight - 288,
                //查询
                selBusinessData:new Business(),
                //新增
                addBusinessData:new Business(),
                //修改
                editBusinessData:new Business(),

                isShowRoleDlg:false,
				roleList: [],
				seletedRole:[],
                currentId:null
			}
		},
        computed : {

        },
		methods:{
			searchBus(){
				this.isShowSelDlg=true;
				this.selBusinessData.reset();
			},
			addBus(){
				this.isShowAddDlg=true;
				this.addBusinessData.reset();
			},
            refresh(){
                this.page.reset();
                this.getModuleList();
			},
			businessConSizeChange(val){
				this.page.reset();
				this.page.pageSize = val;
				this.getModuleList();
			},
			businessConCurrentChange(val){
                this.page.currentPage = val;
                this.getModuleList();
			},
			async getModuleList(){
			    let param= {
                    keyWord:this.selBusinessData.keyWord,
                    moduleName:this.selBusinessData.moduleName,
                    limit: this.page.pageSize,
                    offSet: (this.page.currentPage-1)*this.page.pageSize
				};
			    let res = await this.$http.post('/sys/moduleList',param);
			    if(res.state){
                    this.businessConData = res.data.list;
                    this.page.total = res.data.totalCount;
				}
			},
            async addBusinessRequest(){
                let res = await this.$http.post('/sys/addModule',this.addBusinessData);
                if(res.state){
                    this.$message.success('操作成功');
                    this.getModuleList();
				}
            },
            addBusiness(){
                this.$refs['businessForm'].validate((valid) => {
                    if (valid) {
                        this.addBusinessRequest();
                        this.isShowAddDlg = false;
                        this.page.reset();
                        this.getModuleList();
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            async updateBusinessRequest(){
                let res = await this.$http.post('/sys/updModule',this.editBusinessData);
                if(res.state){
                    this.$message.success('操作成功');
                    this.isShowEditDlg = false;
                    this.getModuleList();
				}
            },
            updateBusiness(){
                this.$refs['businessForm2'].validate((valid) => {
                    if (valid) {
                        this.updateBusinessRequest();
                        this.isShowEditDlg = false;
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
			async delBusinessRequest(id){
			    let res = await this.$http.post('sys/delModule',{id});
			    if(res.state){
			        this.$message.success("操作成功");
                    this.getModuleList();
				}else{
			        if(res.msg){
                        this.$message.success(res.msg);
					}
				}
			},
            delBusiness(data){
				if(!data.useOrg){
                    this.$confirm('此操作将永久删除该业务, 是否继续?', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        this.delBusinessRequest(data.moduleId);
                    }).catch(() => {
                        this.$message({
                            type: 'info',
                            message: '已取消删除'
                        });
                    });
				}else {
				    this.$message.warning('该业务正在被使用，无法删除')
				}
			},
            editBusiness(data){
                console.log(data);
                Object.assign(this.editBusinessData,data);
                this.isShowEditDlg = true;
            },
            searchBusiness(){
                this.page.reset();
                this.getModuleList();
                this.isShowSelDlg = false;
            },

			//封装的让table表格的复选框默认选中的函数
            first(rows){
                this.$nextTick(()=>{
                    rows.forEach(row => {
                        this.$refs.multipleTable.toggleRowSelection(row,true);
                    });
                })
            },
			async getRoleList(moduleId){
			    let res = await this.$http.post('sys/allRoleList',{moduleId});
			    if(res.state){
			        this.roleList = res.data;
				}
                for(let i = 0;i<this.roleList.length;i++){                 //对b循环  如果b里面有a（a是被选中的行） 则把索引代入到first函数里 让这些行的复选框选中
                    // console.log(b[c])
                    // console.log(a.indexOf(b[c]))
                    if(this.roleList[i].isTrue){
                        this.first([this.roleList[i]])
                    }
                }
			},
            roleConfig(data){
				this.isShowRoleDlg = true;
                this.currentId = data.moduleId;
				this.getRoleList(data.moduleId);
			},
            async addModuleRole(){
			    let roleIds = [];
				for(let item of this.seletedRole){
                    roleIds.push(item.id);
				}
				let param={
                    moduleId:this.currentId,
                    roleIds:roleIds
				};
                let res = await this.$http.post('sys/addModuleRole',param);
                if(res.state){
                    this.$message.success("操作成功");
                    this.getModuleList();
                    this.isShowRoleDlg = false;
                }else{
                    if(res.msg){
                        this.$message.success(res.msg);
                    }
                }
			},
            handleSelectionChange(val){
				this.seletedRole = val;
			}
		},
		created(){
		    this.getModuleList();
		},
		mounted() {
			window.onresize = () => {
				window.fullHeight = document.documentElement.clientHeight
				this.tableHeight = window.fullHeight - 283;
			}
		}
	}
</script>

<style>
</style>